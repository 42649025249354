<script setup>
import {to} from "await-to-js";
import {ref, reactive, onMounted, computed} from "vue";
import {cloneDeep} from "lodash";
import {interceptor} from "@dutypay/utilities";
import {useEnvStore} from "@dutypay/store-modules";
import {ElNotification} from "element-plus";

const loading = ref(false);
let isAddPermissionGroupDialogVisible = ref(false);

const addPermissionGroupFormBlank =
    {
      permission: {
        ids: []
      },
      description: {
        text: "",
        url: ""
      }
    };

const addPermissionGroupForm = ref({
  permission: {
    ids: []
  },
  description: {
    text: "",
    url: ""
  }
});

const isEditPermissionGroupDialogVisible = ref(false);
const editPermissionGroupFormBlank = {
  hs: {
    permission: {
      group: {
        id: ""
      }
    }
  },
  permission: {
    ids: []
  },
  description: {
    text: "",
    url: ""
  }
};
const editPermissionGroupForm = ref({
  hs: {
    permission: {
      group: {
        id: ""
      }
    }
  },
  permission: {
    ids: []
  },
  description: {
    text: "",
    url: ""
  }
});
const permissionGroups = ref([]);

const permissions = ref([]);


const queriedPermissionGroupCount = ref(0);
const totalPermissionGroupCount = ref(0);
const envStore = useEnvStore();

const pageSizeOptions = ref([
  {label: '10', value: "10"},
  {label: '25', value: '25'},
  {label: '50', value: '50'}
]);

const parameters = reactive({
  pageSize: pageSizeOptions.value[0].value,
  search: "",
  pageNumber: "1",
});

async function updatePageSize(size) {
  parameters.pageSize = size;
  parameters.pageNumber = "1";
  await getPermissionGroups()
}

const hasPreviousPage = computed(() => {
  return parseInt(parameters.pageNumber, 10) > 1;
});

async function getPreviousPage() {
  parameters.pageNumber = (parseInt(parameters.pageNumber, 10) - 1).toString();
  await getPermissionGroups();
}

const hasNextPage = computed(() => {
  return (queriedPermissionGroupCount.value < totalPermissionGroupCount.value);
});

async function getNextPage() {
  parameters.pageNumber = (parseInt(parameters.pageNumber, 10) + 1).toString();
  await getPermissionGroups();
}

async function getPermissions() {
  loading.value = true;
  let err, res;
  [err, res] = await to(
      interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/permissions`, {})
  );
  if (err) {
    loading.value = false;
    return;
  }
  permissions.value = [];
  permissions.value = res.data.result;
  loading.value = false;
}

async function getPermissionGroups() {
  loading.value = true;
  let err, res;
  [err, res] = await to(
      interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/permission-groups`, {
        params: {
          search: parameters.search,
          pageNumber: parameters.pageNumber,
          pageSize: parameters.pageSize,
        },
      })
  );
  if (err) {
    loading.value = false;
    return;
  }
  permissionGroups.value = [];
  permissionGroups.value = res.data.result.permissionGroupList;
  queriedPermissionGroupCount.value = res.data.result.sqlQueriedPermissionGroupCurrentCount;
  totalPermissionGroupCount.value = res.data.result.sqlQueriedPermissionGroupTotalCount;
  loading.value = false;
}

async function getPermissionGroup(id) {
  let err, res;
  [err, res] = await to(
      interceptor.get(`${envStore.apiUrls.dutypay}/api/v0/permission-groups/${id}`, {})
  );
  if (err) {
    ElNotification({
      title: "Error on getting permission with ID " + id,
      type: "error",
    });
    return;
  }
  return res.data.result;
}

async function addPermissionGroup() {
  try {
    addPermissionGroupForm.value.permission.ids.sort();
    await to(
        interceptor.post(
            `${envStore.apiUrls.dutypay}/api/v0/permission-groups`,
            addPermissionGroupForm.value
        )
    );
    ElNotification({
      title: "PermissionGroup successfully added.",
      type: "success",
    });
    loading.value = false;
    addPermissionGroupForm.value = cloneDeep(addPermissionGroupFormBlank);
    isAddPermissionGroupDialogVisible.value = false;
    await getPermissionGroups();
  } catch (err) {
    loading.value = false;
    ElNotification({
      title: "Error on adding permission",
      type: "error",
    });
    isAddPermissionGroupDialogVisible.value = false;
  }
}


async function handleEditPermissionGroup(index, permission) {
  let id = permission.hs.permission.group.id;
  let err, permissionFromServer;
  [err, permissionFromServer] = await to(getPermissionGroup(id));
  if (err) {
    ElNotification({
      title: "Error on getting permission " + id,
      type: "error",
    });
  }
  editPermissionGroupForm.value = permissionFromServer;
  isEditPermissionGroupDialogVisible.value = true;
}

async function deletePermissionGroup(index, permission) {
  try {
    await to(
        interceptor.delete(`${envStore.apiUrls.dutypay}/api/v0/permission-groups/${permission.hs.permission.group.id}`));
    ElNotification({
      title: "PermissionGroup successfully deleted.",
      type: "success",
    });
    loading.value = false;
    await getPermissionGroups();

  } catch (err) {
    loading.value = false;
    ElNotification({
      title: "Error on deleting permission",
      type: "error",
    });
  }
}

async function editPermissionGroup() {
  try {
    editPermissionGroupForm.value.permission.ids.sort();
    await to(
        interceptor.patch(
            `${envStore.apiUrls.dutypay}/api/v0/permission-groups/${editPermissionGroupForm.value.hs.permission.group.id}`,
            editPermissionGroupForm.value
        )
    );
    ElNotification({
      title: "PermissionGroup successfully edited.",
      type: "success",
    });
    loading.value = false;
    editPermissionGroupForm.value = cloneDeep(editPermissionGroupFormBlank);
    isEditPermissionGroupDialogVisible.value = false;
    await getPermissionGroups();

  } catch (err) {
    loading.value = false;
    ElNotification({
      title: "Error on editing permission",
      type: "error",
    });
    isEditPermissionGroupDialogVisible.value = false;
  }
}

onMounted(async () => {
  await getPermissionGroups();
  await getPermissions();
});
</script>

<script>
import PaginationNavigationComponent from '../../components/PaginationNavigationComponent.vue';
import PaginationFilterOptionsRadioComponent from '../../components/PaginationFilterOptionsRadioComponent.vue';

export default {
  name: "PermissionGroupsPage",
  components: {
    PaginationNavigationComponent,
    PaginationFilterOptionsRadioComponent
  },
};
</script>

<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="24">
        <h1>
          PermissionGroups &nbsp;
          <el-button type="success" @click="isAddPermissionGroupDialogVisible = true">
            Add
          </el-button>
        </h1>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <!--<el-col :span="8" :xl="8" :lg="8" :sm="24" :xs="24" class="searchform">
        <el-form-item style="flex-grow: 1">
          <el-input
              v-model="parameters.search"
              @click="getPermissionGroups()"
              @change="parameters.pageNumber = 1"
              placeholder="Search for hs.id, name"
              :clearable="true"
          >
          </el-input>
        </el-form-item>
        <el-form-item style="padding-left: 1rem; flex-grow: 0">
          <el-button type="primary" @click="getPermissionGroups"> Search</el-button>
        </el-form-item>
      </el-col>-->
      <el-col
          :span="16"
          :xl="16"
          :lg="16"
          :sm="24"
          :xs="24"
          class="pagesize"
          style="display: flex; justify-content: flex-end"
      >
        <pagination-filter-options-radio-component
            label="PermissionGroups per page"
            v-model="parameters.pageSize"
            :options="pageSizeOptions"
            :formItemStyle="'padding-top: 2px;'"
            :onChange="updatePageSize"
        ></pagination-filter-options-radio-component>
      </el-col>
    </el-row>
    <el-row>
      <pagination-navigation-component
          :leftIconClass="'el-icon--left'"
          :rightIconClass="'el-icon--right'"
          :previousLabel="'Previous'"
          :nextLabel="'Next'"
          :hasPrevious="hasPreviousPage"
          :hasNext="hasNextPage"
          :onPrevious="getPreviousPage"
          :onNext="getNextPage"
      ></pagination-navigation-component>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table :data="permissionGroups" stripe style="width: 100%">
          <el-table-column prop="hs.permission.group.id" label="HubSystem Permission Group ID"/>
          <el-table-column prop="permission.ids" label="HubSystem Permission IDs"/>
          <el-table-column prop="description.text" label="Description as text"/>
          <el-table-column prop="description.url" label="Description URL"/>
          <el-table-column label="Actions">
            <template #default="scope">
              <el-button type="warning" @click="handleEditPermissionGroup(scope.$index, scope.row)"
              >Edit
              </el-button>
              <el-button type="danger" @click="deletePermissionGroup(scope.$index, scope.row)"
              >Delete
              </el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <el-dialog
        v-model="isAddPermissionGroupDialogVisible"
        title="Add PermissionGroup"
        width="1000px"
        align-center
        draggable
    >
      <el-form
          :model="addPermissionGroupForm"
          label-position="left"
          label-width="300px"
          style="max-width: 1000px"
      >
        <el-form-item label="HubSystem Resource ID">
          <el-select v-model="addPermissionGroupForm.permission.ids" multiple clearable placeholder="Select">
            <el-option
                v-for="item in permissions"
                :key="item.hs.permission.id"
                :label="item.name"
                :value="item.hs.permission.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Description">
          <el-input v-model="addPermissionGroupForm.description.text"/>
        </el-form-item>
        <el-form-item label="Description URL">
          <el-input v-model="addPermissionGroupForm.description.url"/>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isAddPermissionGroupDialogVisible = false"
          >Cancel</el-button
          >
          <el-button type="primary" @click="addPermissionGroup">
            Add PermissionGroup
          </el-button>
        </span>
      </template>
    </el-dialog>


    <el-dialog
        v-model="isEditPermissionGroupDialogVisible"
        :title="'Edit PermissionGroup \'' + editPermissionGroupForm.hs.permission.group.id + '\''"
        width="1000px"
        align-center
        draggable
    >
      <el-form
          :model="editPermissionGroupForm"
          label-position="left"
          label-width="300px"
          style="max-width: 1000px"
      >
        <el-form-item label="Permission IDs">
          <el-select v-model="editPermissionGroupForm.permission.ids" multiple clearable placeholder="Select">
            <el-option
                v-for="item in permissions"
                :key="item.hs.permission.id"
                :label="item.name"
                :value="item.hs.permission.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Description">
          <el-input v-model="editPermissionGroupForm.description.text"/>
        </el-form-item>
        <el-form-item label="Description URL">
          <el-input v-model="editPermissionGroupForm.description.url"/>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isEditPermissionGroupDialogVisible = false"
          >Cancel</el-button
          >
          <el-button type="primary" @click="editPermissionGroup">
            Edit PermissionGroup
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss">
.searchform {
  display: inline-flex !important;
}
</style>

<i18n>
{
  "en-US": {
  }
}
</i18n>
